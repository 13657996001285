import CryptoJS from "crypto-js";

export const VEHICLE_TYPE = {
  FOUR_WHEELER: "4w",
  TWO_WHEELER: "2w",
  GCV: "gcv",
  PCV: "pcv",
};

export const CUSTOMER_TYPE = {
  INDIVIDUAL: "individual",
  ORGANISATION: "organisation",
};

export const POLICY_TYPE = {
  COMPREHENSIVE: "comprehensive",
  THIRDPARTY: "thirdParty",
  ODONLY: "odOnly",
  BUNDLED: "comprehensive",
};

export const POLICY_STATUS = {
  NOTEXPIRED: "rollover",
  EXPIREDWITHIN90: "breakInWithin90Days",
  EXPIREDBEFORE90: "breakInBefore90Days",
  NEW: "new",
  USED: "used",
};

export const kycSteps = {
  before_proposal: "before_proposal",
  in_proposal: "in_proposal",
  before_payment: "before_payment",
  before_policy_doc: "before_policy_doc",
};

export const kycStepPriority = {
  before_proposal: 1,
  in_proposal: 2,
  before_payment: 3,
  before_policy_doc: 4,
};

export const caseStatus = {
  quotesRequested: "Quotes Requested",
  quotesInProcess: "Quotes In Process",
  quotesGenerated: "Quotes Generated",
  proposalRequest: "Proposal Requested",
  proposalPremiumMismatch: "Proposal Premium Mismatch",
  proposalSuccess: "Proposal Successful",
  proposalFailure: "Proposal Failure",
  paymentRequested: "Payment Requested",
  paymentSuccess: "Payment Success",
  paymentPending: "Payment Pending",
  paymentFailure: "Payment Failed",
  policyDocRequested: "Policy Document Requested",
  policyDocSuccess: "Policy Document Success",
  policyDocPending: "Policy Document Pending",
  policyDocFailure: "Policy Document Fail",
};

export const kycStatus = {
  success: "success",
  failure: "failure",
  pending: "pending",
};

export const kycDoc = {
  passport: "passport",
  voterId: "voterId",
  pan: "pan",
  dl: "dl",
  adhaar: "adhaar",
  nregaCard: "nregaCard",
  gstin: "gstin",
  ckycNumber: "ckycNumber",
};

export const kycType = {
  ckyc: "ckyc",
  ovd: "ovd",
};

export const decryptAes256Text = (encrypted, key) => {
  // Decode base64 encoded string to a WordArray
  const input = CryptoJS.enc.Base64.parse(encrypted);

  // Extract the IV (first 16 bytes) and the ciphertext (remaining bytes)
  const iv = CryptoJS.lib.WordArray.create(input.words.slice(0, 4), 16); // First 16 bytes (128 bits)
  const ciphertext = CryptoJS.lib.WordArray.create(
    input.words.slice(4),
    input.sigBytes - 16
  ); // Remaining bytes

  // Hash the key using SHA-256
  const hashedKey = CryptoJS.SHA256(key);

  // Decrypt the ciphertext using the hashed key and IV
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    hashedKey,
    {
      iv: iv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding, // NoPadding because CTR mode doesn't require padding
    }
  );

  // Convert decrypted WordArray to UTF-8 string
  console.log('decrypted', decrypted)
  return CryptoJS.enc.Utf8.stringify(decrypted);
};

export const extractCustomerLeadId = (url) => {
  // Use regex to match the pattern after "quotelist/"
  const match = url.match(/\/([^\/]+)\/?$/);

  // Return the first capture group if match is found
  return match ? match[1] : null;
};

export const insurers = {
  Digit: {
    name: "Go Digit General Insurance Limited",
    insurerId: 13,
    logo: "/assets/images/logos/partners/digit.png",
  },
  Bajaj: {
    name: "Bajaj Allianz General Insurance Co. Ltd.",
    insurerId: 5,
    logo: "/assets/images/logos/partners/bajaj.png",
  },
  Future: {
    name: "Future Generali India Insurance Company Limited",
    insurerId: 12,
    logo: "/assets/images/furturegeneral.png",
  },
  Kotak: {
    name: "Zurich Kotak General Insurance Company (India) Limited",
    insurerId: 17,
    logo: "/assetss/images/ZK Horizontal.png",
  },
  ICICI: {
    name: "ICICI Lombard General Insurance Co. Ltd.",
    insurerId: 15,
    logo: "/assetss/images/icici.jpeg",
  },
  TATA: {
    name: "Tata AIG General Insurance Co. Ltd.",
    insurerId: 30,
    logo: "/assetss/images/TataLogo.png",
  },
  HDFC: {
    name: "HDFC",
    insurerId: 14,
    logo: "/assets/images/hdfcegro.jpeg",
  },
  Zuno: {
    name: 'Zuno General Insurance Ltd.',
    insurerId: 10,
    logo: '/assets/images/logos/partners/zuno.png'
  },
  Magma:{
    name :"Magma HDI General Insurance Company Limited",
    insurerId:19,
    logo:"/assets/images/logos/partners/magma.png"
  }
};

export const Covers = [
  {
    label: "Personal Accident Cover",
    value: "personalAccident",
    type: "select",
  },
  {
    label: "Electrical Accessories",
    value: "isElectricalAccessories",
    type: "input",
    min: "1000",
    inputName: "electricAmount",
  },
  {
    label: "Non-Electrical Accessories",
    value: "isNonElectricalAccessories",
    type: "input",
    min: "1000",
    inputName: "nonElectricAmount",
  },
  {
    label: "Is Bi-fuel Kit?",
    value: "isCngAccessories",
    type: "input",
    min: 0,
    inputName: "cngValue",
  },
  {
    label: "Passenger Cover",
    value: "isPassengerCover",
    type: "input",
    min: "10000",
    inputName: "passengerCoverAmount",
    options: {
      0: "Select Amount",
      1: 10000,
      2: 50000,
      3: 100000,
      4: 200000,
    },
  },
  {
    label: "Legal Libability To Paid Driver",
    value: "liabilitiesToPaidDriver",
    type: "input",
    min: "10000",
    inputName: "noOfPaidDrivers",
    options: {
      0: "Select",
      1: 1,
      2: 2,
    },
  },
];
export const odCovers = [
  {
    label: "Electrical Accessories",
    value: "isElectricalAccessories",
    type: "input",
    min: "1000",
    inputName: "electricAmount",
  },
  {
    label: "Non-Electrical Accessories",
    value: "isNonElectricalAccessories",
    type: "input",
    min: "1000",
    inputName: "nonElectricAmount",
  },
  {
    label: "Is Bi-fuel Kit?",
    value: "isCngAccessories",
    type: "input",
    min: 0,
    inputName: "cngValue",
  },
];
export const tpCovers = [
  {
    label: "Personal Accident Cover",
    value: "personalAccident",
    type: "select",
  },
  {
    label: "Passenger Cover",
    value: "isPassengerCover",
    type: "input",
    min: "10000",
    inputName: "passengerCoverAmount",
    options: {
      0: "Select Amount",
      1: 10000,
      2: 50000,
      3: 100000,
      4: 200000,
    },
  },
  {
    label: "Legal Libability To Paid Driver",
    value: "liabilitiesToPaidDriver",
    type: "input",
    min: "10000",
    inputName: "noOfPaidDrivers",
    options: {
      0: "Select",
      1: 1,
      2: 2,
    },
  },
];
export const addOnsData = [
  { label: "Zero Dep Cover", value: "zeroDepriciationCover", type: "select" },
  {
    label: "Road Side Assistance",
    value: "roadSideAssistance",
    type: "select",
  },
  { label: "Consumables", value: "consumables", type: "select" },
  { label: "Key Replacement", value: "keyReplacement", type: "select" },
  { label: "Invoice Cover", value: "invoiceCover", type: "select" },
  // { label: "Engine Gearbox Cover", value: "engineGearBox", type: "select" },
  { label: "NCB Protection", value: "ncbProtection", type: "select" },
  {
    label: "Loss Of Personal Belongings",
    value: "lossOfPersonalBelongings",
    type: "select",
  },
  { value: "engineProtection", label: "Engine Protection", type: "select" },
  { value: "tyreProtection", label: "Tyre Protection", type: "select" },
  // {
  //   label: "Tppd Discount",
  //   value: "isTppdDiscount",
  //   type: "select",
  // },
  { value: "RimProtection", label: "Rim Protection", type: "select" },

  {
    label: "Voluntary Deductive",
    value: "VoluntaryDeductive",
    type: "input",
    min: 0,
    inputName: "VoluntaryDeductiveAmount",
  },
];

export const kycDocName = {
  passport: "Passport",
  voterId: "Voter Id",
  pan: "Pan",
  dl: "Driving License",
  adhaar: "Aadhaar Card",
  nregaCard: "Nrega Card",
  gstin: "GSTIN",
  ckycNumber: "Ckyc Number",
};

export const addonsQues = {
  zeroDep:{
    ques:"zeroDepriciationCover",
    prevAddon: 'isPrevZeroDepreciationCover',
    label:  "Zero Depreciation Cover",
  },
  invoiceCover:{
    ques:"invoiceCover",
    prevAddon: 'isPrevInvoiceCover',
    label:  "Invoice Cover",
  },
  engineProtection:{
    ques:"engineProtection",
    prevAddon: 'isPrevEngineProtection',
    label:  "Engine Protection",
  },
  tyreProtection:{
    ques:"tyreProtection",
    prevAddon: 'isPrevTyreProtection',
    label:  "Tyre Protection",
  },
}
export const reasonForNotOptingCpa = {
  1: "Owner Driver does not hold valid Driving License",
  2: "Insured has standalone PA cover >= 15 lakhs",
};